import React, { Component, useEffect } from "react"
import Box from "@mui/material/Box"
import DataTable from "react-data-table-component"
import { Column } from "primereact/column"
import { useState } from "react"
import { GetBetsPerAgent, GetBets } from "../../services/AcceptedBetsServices"
import Button from "@mui/material/Button"
import Modal from "react-bootstrap/Modal"
import { useGlobalState, setGlobalState } from "../../helpers/state"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { Navigate } from "react-router-dom"
import { InputLabel } from "@mui/material"
import { Print, PrintDisabled } from "@mui/icons-material"
import Loading from "../../img/spinner.gif"

const AcceptedBets = () => {
  let user = localStorage.getItem("usercode")
  const MySwal = withReactContent(Swal)
  var todayDate = new Date()
    .toISOString("en-US", {
      timeZone: "Asia/Manila",
    })
    .slice(0, 10)

  // Many options with Intl.DateTimeFormat
  const formatter = new Intl.DateTimeFormat("en", {
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
  })
  let time = formatter.format(new Date())

  let NEXTLINE_Char = "\u000D\u000A"
  let Feed_LINE = new TextDecoder().decode(new Uint8Array([10]))
  let Bold_str = new TextDecoder().decode(new Uint8Array([0x1b, 0x21, 0x08]))
  let normal_str = new TextDecoder().decode(new Uint8Array([0x1b, 0x21, 0x00]))

  const [printCharacteristic2, setPrintCharacteristic2] = useGlobalState(
    "printCharacteristic"
  )
  const [acceptedbets, setAcceptedbets] = useState([])
  const [betsclick, setBetsclick] = useState([])
  const [selectedtrans, setSelectedTrans] = useState(null)
  const [totalgross, setTotalGross] = useState(null)

  const betscolumns = [
    {
      name: "Drawcode",
      selector: (row) => row.drawcode,
    },
    {
      name: "Combination",
      selector: (row) => row.combination,
    },
    {
      name: "Bet",
      selector: (row) => row.betamount,
    },
  ]

  const columns = [
    {
      name: "Trans ID",
      selector: (row) => row.transactionid,
    },
    {
      name: "Draw Date",
      selector: (row) => row.drawdate,
    },
    {
      name: "Gross",
      selector: (row) => row.totalbet,
    },
  ]
  const LoadBets = async () => {
    let data = {
      usercode: localStorage.getItem("usercode"),
      date: todayDate,
    }
    let result = await GetBetsPerAgent(data)
    setAcceptedbets(result)

    if (result.length != 0) {
      setTotalGross(result[0].Grosstotal)
    }
  }

  const test = async () => {
    // Get the bytes for the text
    let encoder = new TextEncoder("utf-8")
    // Add line feed + carriage return chars to text
    let text = encoder.encode(
      NEXTLINE_Char +
        TotalAmount() +
        NEXTLINE_Char +
        normal_str +
        "--------------------------------" +
        NEXTLINE_Char +
        "Outlet No.: " +
        localStorage.getItem("usercode") +
        NEXTLINE_Char +
        "Sales Rep.: " +
        localStorage.getItem("agentname") +
        NEXTLINE_Char +
        NEXTLINE_Char
    )
    return printCharacteristic2
      .writeValue(text)
      .then(async () => {
        await EndlineText()
      })
      .catch((res) => {
        Swal.close()
        MySwal.fire({
          title: <strong>Error</strong>,
          html: <i>Something went wrong, Please try again!</i>,
          icon: "error",
        })
      })
  }

  const EndlineText = async () => {
    // Get the bytes for the text
    let encoder = new TextEncoder("utf-8")
    // Add line feed + carriage return chars to text
    let text = encoder.encode(
      Bold_str +
        "      *REPRINTED RECEIPT*" +
        normal_str +
        NEXTLINE_Char +
        NEXTLINE_Char +
        "Winning tickets should be claimed within one (1) week after the bet date, otherwise winning priz" +
        "      e shall be forfeited" +
        NEXTLINE_Char +
        NEXTLINE_Char +
        Feed_LINE +
        Feed_LINE
    )
    return printCharacteristic2
      .writeValue(text)
      .then(async () => {})
      .catch((res) => {
        Swal.close()
        MySwal.fire({
          title: <strong>Error</strong>,
          html: <i>Something went wrong, Please try again!</i>,
          icon: "error",
        })
      })
  }

  const Reprint = async () => {
    if (printCharacteristic2 != null) {
      try {
        // Get the bytes for the text
        let encoder = new TextEncoder("utf-8")
        // Add line feed + carriage return chars to text
        let text = encoder.encode(
          normal_str +
            NEXTLINE_Char +
            "       Small Town Lottery" +
            NEXTLINE_Char +
            "      PLUTUS GAMING CORP" +
            NEXTLINE_Char +
            "           Manila, Ph" +
            NEXTLINE_Char +
            NEXTLINE_Char +
            "Transaction ID: " +
            selectedtrans +
            NEXTLINE_Char +
            "Draw Date: " +
            todayDate +
            NEXTLINE_Char +
            "Printed: " +
            `${todayDate} ${time}` +
            NEXTLINE_Char +
            "Draw    Comb.   Bet   Win" +
            NEXTLINE_Char
        )
        return printCharacteristic2
          .writeValue(text)
          .then(async () => {
            await BetsPrint_row()
            MySwal.fire({
              title: <strong>Success</strong>,
              html: <i>Bets Reprinted!</i>,
              icon: "success",
              timer: 2500,
            })
          })
          .catch((res) => {
            Swal.close()
            MySwal.fire({
              title: <strong>Error</strong>,
              html: <i>Something went wrong, Please try again!</i>,
              icon: "error",
            })
          })
      } catch (error) {
        Swal.close()
        MySwal.fire({
          title: <strong>Error</strong>,
          html: <i>Something went wrong, Please try again!</i>,
          icon: "error",
        })
      }
    } else {
      Swal.close()
      MySwal.fire({
        title: <strong>NOTICE</strong>,
        html: <i>Please connect to the printer!</i>,
        icon: "warning",
      })
    }
  }

  const BetsToprint = () => {
    let result = ""
    console.log([...betsclick])
    betsclick.forEach((row) => {
      result +=
        row.drawcode +
        "   " +
        row.combination +
        "   " +
        row.betamount +
        "    " +
        row.winningprize +
        NEXTLINE_Char
    })
    return result
  }

  const BetsPrint_row = async () => {
    // Get the bytes for the text
    let encoder = new TextEncoder("utf-8")
    // Add line feed + carriage return chars to text
    let text = encoder.encode(await BetsToprint())
    return printCharacteristic2.writeValue(text).then(async () => {
      await test()
    })
  }

  const ConnectToPrinter = () => {
    if (printCharacteristic2 == null) {
      navigator.bluetooth
        .requestDevice({
          filters: [
            {
              services: ["000018f0-0000-1000-8000-00805f9b34fb"],
            },
          ],
        })
        .then((device) => {
          console.log("> Found " + device.name)
          console.log("Connecting to GATT Server...")
          Swal.fire({
            title: "Please wait!",
            text: "Connecting to Printer...",
            imageUrl: Loading,
            imageWidth: 200,
            imageHeight: 200,
            allowOutsideClick: false,
            showConfirmButton: false,
          })
          return device.gatt.connect()
        })
        .then((server) =>
          server.getPrimaryService("000018f0-0000-1000-8000-00805f9b34fb")
        )
        .then((service) =>
          service.getCharacteristic("00002af1-0000-1000-8000-00805f9b34fb")
        )
        .then((characteristic) => {
          // Cache the characteristic
          setPrintCharacteristic2(characteristic)

          Swal.close()
        })
        .catch(() => {
          setPrintCharacteristic2(null)
          Swal.close()
          MySwal.fire({
            title: <strong>Error</strong>,
            html: <i>Can't Connect to the printer, Please try again!</i>,
            icon: "error",
          })
        })
    } else {
      MySwal.fire({
        title: <strong>NOTICE</strong>,
        html: <i>Your'e already Connected!</i>,
        icon: "info",
      })
    }
  }
  const TotalAmount = () => {
    let TotalAmount = 0
    betsclick.forEach((row) => {
      TotalAmount += parseInt(row.betamount)
    })
    return "TOTAL: " + TotalAmount + ".00"
  }

  const Pinterstat = () => {
    if (printCharacteristic2 != null) {
      return (
        <Button
          color="success"
          sx={{
            m: 1,
            width: "auto",
          }}
          variant="contained"
          startIcon={<Print />}
          onClick={() => ConnectToPrinter()}
        >
          Connected
        </Button>
      )
    } else {
      return (
        <Button
          color="error"
          sx={{
            m: 1,
            width: "auto",
          }}
          variant="contained"
          startIcon={<PrintDisabled />}
          onClick={() => ConnectToPrinter()}
        >
          Disconnected
        </Button>
      )
    }
  }

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {selectedtrans}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={betscolumns}
            data={betsclick}
            fixedHeader
            pagination
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => Reprint()}>
            <i className="pi pi-print" style={{ fontSize: "2em" }}></i>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const onClickBet = async (row) => {
    let data = {
      usercode: localStorage.getItem("usercode"),
      date: todayDate,
      transactionid: row.transactionid,
    }
    setSelectedTrans(row.transactionid)
    setBetsclick(await GetBets(data))
    // console.log(test)
    setModalShow(true)
  }
  const [modalShow, setModalShow] = React.useState(false)
  // useEffect(() => {
  //   LoadBets()
  //   console.log(acceptedbets)
  //   console.log(todayDate)
  // }, [])

  useEffect(() => {
    LoadBets()
    // Pinterstat()
    // console.log(localStorage.getItem("usercode"))
    // console.log(localStorage.getItem("agentname"))
  }, [])
  return (
    <div>
      {user == null ? <Navigate to="/" replace={true} /> : null}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="card">
        <Box
          sx={{
            display: "flex",
            width: "100%",
            "@media (min-width: 780px)": {
              width: "50%",
            },
            position: "absolute",
            justifyContent: "center",
            top: 20,
          }}
        >
          <label
            style={{
              marginLeft: "10px",
              fontWeight: "bold",
              color: "gray",
              fontSize: "20px",
              zIndex: 1000,
            }}
          >
            Total : {totalgross}
          </label>
        </Box>
        <Box
          sx={{
            // display: "flex",
            width: "100%",
            "@media (min-width: 780px)": {
              width: "50%",
            },
            position: "absolute",
            justifyContent: "center",
            top: 40,
          }}
        >
          <DataTable
            columns={columns}
            data={acceptedbets}
            onRowClicked={(row) => onClickBet(row)}
            highlightOnHover
            fixedHeader
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          />
        </Box>
      </div>
      <Box
        component="form"
        sx={{
          // mx: 2,
          // display: "flex",
          width: "100%",
          position: "absolute",
          justifyContent: "right",
          bottom: 70,
        }}
      >
        <InputLabel sx={{ mx: 2 }}>
          <b>Printer Status:</b>
          <Pinterstat />
        </InputLabel>
      </Box>
    </div>
  )
}
export default AcceptedBets
