import axios from "axios"

import url from "./ApiURL"

const API_URL = url

export const UserLogin = async (body) => {
  try {
    const { data } = await axios.post(`${API_URL}login/loginpasscode`, body)
    return data
  } catch (error) {
    console.log(error)
  }
}
